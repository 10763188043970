import "./App.css"

import { useConfig } from "./Config"

function App() {
	const { getConfig } = useConfig()

	console.log(getConfig("REALM_APP_ID"))

	return (
		<div className="App">
			<header className="App-header">Hello</header>
		</div>
	)
}

export default App
