import createConfig from "react-runtime-config"

/**
 * `useConfig` and `useAdminConfig` are now React hooks that you can use in your app.
 *
 * `useConfig` provides config getter & setter, `useAdminConfig` provides data in order
 * to visualize your config map with ease. More on this further down.
 */
export const { useConfig, useAdminConfig } = createConfig({
	namespace: "VETSTAR_CONFIG",
	schema: {
		REALM_APP_ID: {
			type: "string",
		},
	},
})
